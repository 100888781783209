import {WorkloadConfig} from "../integrations/validator.ts";
import {getItemAnon} from "../../utils/localStorage.ts";

export const successNotificationTimeout = 8000;
export const infoNotificationTimeout = 8000;


const generateDummyButtons = () => {
    const primaryCtaTo = "/test-service/deploy";
    const primaryCtaBluexpTo = "storage/test-service/deploy";
    const primaryCtaLabel= "Create the best service ever";
    const secondaryCtaLabel = "Manage workload";
    const primaryCtaDropdownLabel = "Deploy";


    const primaryDropdown = [
        {
            to: primaryCtaTo,
            bluexpTo: primaryCtaBluexpTo,
            label: primaryCtaDropdownLabel,
            isDisabled: false
        },
        {
            to: "/test-service/deploy2",
            bluexpTo: "storage/test-service/deploy2",
            label: "Deploy2",
            isDisabled: true,
            disabledTooltip: "This is disabled"
        }
    ];

    const hasPrimaryAndDropdownCta = getItemAnon({itemKey: 'feature.Platform.DummyWorkload/primaryAndDropdownCta'});
    const hasDropdownCta = getItemAnon({itemKey: 'feature.Platform.DummyWorkload/dropdownCta'});

    if (hasPrimaryAndDropdownCta) {
        return {
            primaryCtaTo,
            primaryCtaBluexpTo,
            primaryCtaLabel,
            secondaryCtaLabel,
            primaryCtaDropdownLabel,
            primaryCtaDropdownTo: primaryDropdown,
        };
    } else if (hasDropdownCta) {
        return {
            primaryCtaDropdownLabel,
            primaryCtaDropdownTo: primaryDropdown,
        };
    } else {
        return {
            primaryCtaTo,
            primaryCtaBluexpTo,
            primaryCtaLabel,
        };
    }
};

export const localWorkloadsConfig: (WorkloadConfig | any)[] = [{
    "name": "Test Service",
    "to": "/test-service",
    id: "test-service",
    features: [
        {
            "title": "Dummy Workload",
            "id": "Platform.DummyWorkload/*",
            "isAnon": true
        },
        {
            "title": "Dummy Workload - is active",
            "id": "Platform.DummyWorkload/active",
            "isAnon": true
        },
        {
            "title": "Dummy Workload - dropdown CTA",
            "id": "Platform.DummyWorkload/dropdownCta",
            "isAnon": true
        },{
            "title": "Dummy Workload - primary CTA + dropdown CTA",
            "id": "Platform.DummyWorkload/primaryAndDropdownCta",
            "isAnon": true
        },
    ],
    stageIntegrations: [{
        path: "/",
        renderType: "iframe/v2",
        "iframeUrl": "/test-service.html"
    }],
    homepage: {
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type.",
        secondaryCtaLabel: "Manage workload",
        ...generateDummyButtons(),
        secondaryCtaTo: "/test-service/manage",
        secondaryCtaBluexpTo: "storage/test-service/manage",
        isComingSoon: false,
        dataUrl: `/dummy-workload/v1/is-active${getItemAnon({itemKey: `feature.Platform.DummyWorkload/active`}) ? "?activity=true" : "?activity=false"}`,
        featureId: "Platform.DummyWorkload/*"
    },
    "logo": "<svg width=\"80\" height=\"80\" viewBox=\"0 0 80 80\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n" +
        "<rect width=\"80\" height=\"80\" rx=\"4\" fill=\"#E8EEFD\"/>\n" +
        "<g filter=\"url(#filter0_d_6417_386)\">\n" +
        "<path d=\"M62.5535 21.0045C62.6006 20.6765 62.625 20.3411 62.625 20C62.625 16.134 59.491 13 55.625 13C52.9383 13 50.6051 14.5136 49.4317 16.7347C48.7929 16.0518 47.8838 15.625 46.875 15.625C44.942 15.625 43.375 17.192 43.375 19.125V19.1976C40.892 19.6142 39 21.7736 39 24.375C39 25.3313 39.2557 26.2278 39.7024 27H65.8151C66.5417 26.3587 67 25.4204 67 24.375C67 22.442 65.433 20.875 63.5 20.875C63.172 20.875 62.8545 20.9201 62.5535 21.0045Z\" fill=\"white\"/>\n" +
        "</g>\n" +
        "<g filter=\"url(#filter1_d_6417_386)\">\n" +
        "<path d=\"M29.4591 20.574C29.4861 20.3865 29.5 20.1949 29.5 20C29.5 17.7909 27.7091 16 25.5 16C23.9647 16 22.6315 16.8649 21.9609 18.1341C21.596 17.7439 21.0765 17.5 20.5 17.5C19.3954 17.5 18.5 18.3954 18.5 19.5V19.5415C17.0811 19.7795 16 21.0135 16 22.5C16 23.0464 16.1461 23.5587 16.4013 24H31.3229C31.7381 23.6335 32 23.0973 32 22.5C32 21.3954 31.1046 20.5 30 20.5C29.8126 20.5 29.6312 20.5258 29.4591 20.574Z\" fill=\"white\"/>\n" +
        "</g>\n" +
        "<path d=\"M56.3774 29.722C56.4582 29.1596 56.5 28.5847 56.5 28C56.5 21.3726 51.1274 16 44.5 16C39.8942 16 35.8944 18.5948 33.8828 22.4023C32.7879 21.2317 31.2294 20.5 29.5 20.5C26.1863 20.5 23.5 23.1863 23.5 26.5V26.6244C19.2434 27.3386 16 31.0405 16 35.5C16 37.1393 16.4383 38.6762 17.204 40H61.9687C63.2144 38.9006 64 37.292 64 35.5C64 32.1863 61.3137 29.5 58 29.5C57.4377 29.5 56.8935 29.5774 56.3774 29.722Z\" fill=\"url(#paint0_linear_6417_386)\"/>\n" +
        "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M43.874 47C43.5122 45.5944 42.4056 44.4878 41 44.126V42H39V44.126C37.5944 44.4878 36.4878 45.5944 36.126 47H22C21.4477 47 21 47.4477 21 48V52H23V49H36.126C36.5701 50.7252 38.1362 52 40 52C41.8638 52 43.4299 50.7252 43.874 49H57V52H59V48C59 47.4477 58.5523 47 58 47H43.874Z\" fill=\"url(#paint1_linear_6417_386)\"/>\n" +
        "<path d=\"M22 60.8632C19.1811 60.8632 16.8479 60.2325 16 59.3333V62.0125C16 63.0896 18.7476 64 22 64C25.2524 64 28 63.0898 28 62.0125V59.3333C27.1524 60.2325 24.8187 60.8632 22 60.8632Z\" fill=\"url(#paint2_linear_6417_386)\"/>\n" +
        "<path d=\"M28 63.3333C27.1524 64.2325 24.8187 64.863 22 64.863C19.1813 64.863 16.8479 64.2325 16 63.3333V66.0124C16 67.0898 18.7476 68 22 68C25.2524 68 28 67.0898 28 66.0124V63.3333Z\" fill=\"url(#paint3_linear_6417_386)\"/>\n" +
        "<path d=\"M22 52C18.7477 52 16 52.9159 16 53.9999C16 55.084 18.7476 56 22 56C25.2524 56 28 55.0841 28 53.9999C28 52.9158 25.2524 52 22 52Z\" fill=\"url(#paint4_linear_6417_386)\"/>\n" +
        "<path d=\"M22 56.8631C19.1811 56.8631 16.8479 56.2325 16 55.3333V58.0124C16 59.0898 18.7476 60 22 60C25.2524 60 28 59.0898 28 58.0124V55.3333C27.1524 56.2325 24.8187 56.8631 22 56.8631Z\" fill=\"url(#paint5_linear_6417_386)\"/>\n" +
        "<path d=\"M58 60.8632C55.1811 60.8632 52.8479 60.2325 52 59.3333V62.0125C52 63.0896 54.7476 64 58 64C61.2524 64 64 63.0898 64 62.0125V59.3333C63.1524 60.2325 60.8187 60.8632 58 60.8632Z\" fill=\"url(#paint6_linear_6417_386)\"/>\n" +
        "<path d=\"M64 63.3333C63.1524 64.2325 60.8187 64.863 58 64.863C55.1813 64.863 52.8479 64.2325 52 63.3333V66.0124C52 67.0898 54.7476 68 58 68C61.2524 68 64 67.0898 64 66.0124V63.3333Z\" fill=\"url(#paint7_linear_6417_386)\"/>\n" +
        "<path d=\"M58 52C54.7477 52 52 52.9159 52 53.9999C52 55.084 54.7476 56 58 56C61.2524 56 64 55.0841 64 53.9999C64 52.9158 61.2524 52 58 52Z\" fill=\"url(#paint8_linear_6417_386)\"/>\n" +
        "<path d=\"M58 56.8631C55.1811 56.8631 52.8479 56.2325 52 55.3333V58.0124C52 59.0898 54.7476 60 58 60C61.2524 60 64 59.0898 64 58.0124V55.3333C63.1524 56.2325 60.8187 56.8631 58 56.8631Z\" fill=\"url(#paint9_linear_6417_386)\"/>\n" +
        "<defs>\n" +
        "<filter id=\"filter0_d_6417_386\" x=\"35\" y=\"9\" width=\"40\" height=\"26\" filterUnits=\"userSpaceOnUse\" color-interpolation-filters=\"sRGB\">\n" +
        "<feFlood flood-opacity=\"0\" result=\"BackgroundImageFix\"/>\n" +
        "<feColorMatrix in=\"SourceAlpha\" type=\"matrix\" values=\"0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0\" result=\"hardAlpha\"/>\n" +
        "<feOffset dx=\"2\" dy=\"2\"/>\n" +
        "<feGaussianBlur stdDeviation=\"3\"/>\n" +
        "<feComposite in2=\"hardAlpha\" operator=\"out\"/>\n" +
        "<feColorMatrix type=\"matrix\" values=\"0 0 0 0 0.878431 0 0 0 0 0.878431 0 0 0 0 0.878431 0 0 0 1 0\"/>\n" +
        "<feBlend mode=\"normal\" in2=\"BackgroundImageFix\" result=\"effect1_dropShadow_6417_386\"/>\n" +
        "<feBlend mode=\"normal\" in=\"SourceGraphic\" in2=\"effect1_dropShadow_6417_386\" result=\"shape\"/>\n" +
        "</filter>\n" +
        "<filter id=\"filter1_d_6417_386\" x=\"12\" y=\"12\" width=\"28\" height=\"20\" filterUnits=\"userSpaceOnUse\" color-interpolation-filters=\"sRGB\">\n" +
        "<feFlood flood-opacity=\"0\" result=\"BackgroundImageFix\"/>\n" +
        "<feColorMatrix in=\"SourceAlpha\" type=\"matrix\" values=\"0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0\" result=\"hardAlpha\"/>\n" +
        "<feOffset dx=\"2\" dy=\"2\"/>\n" +
        "<feGaussianBlur stdDeviation=\"3\"/>\n" +
        "<feComposite in2=\"hardAlpha\" operator=\"out\"/>\n" +
        "<feColorMatrix type=\"matrix\" values=\"0 0 0 0 0.878431 0 0 0 0 0.878431 0 0 0 0 0.878431 0 0 0 1 0\"/>\n" +
        "<feBlend mode=\"normal\" in2=\"BackgroundImageFix\" result=\"effect1_dropShadow_6417_386\"/>\n" +
        "<feBlend mode=\"normal\" in=\"SourceGraphic\" in2=\"effect1_dropShadow_6417_386\" result=\"shape\"/>\n" +
        "</filter>\n" +
        "<linearGradient id=\"paint0_linear_6417_386\" x1=\"40\" y1=\"16\" x2=\"40\" y2=\"58.6667\" gradientUnits=\"userSpaceOnUse\">\n" +
        "<stop stop-color=\"#0BAFFC\"/>\n" +
        "<stop offset=\"1\" stop-color=\"#0BAFFC\" stop-opacity=\"0\"/>\n" +
        "</linearGradient>\n" +
        "<linearGradient id=\"paint1_linear_6417_386\" x1=\"40\" y1=\"42\" x2=\"40\" y2=\"59.7778\" gradientUnits=\"userSpaceOnUse\">\n" +
        "<stop stop-color=\"#0BAFFC\"/>\n" +
        "<stop offset=\"1\" stop-color=\"#0BAFFC\" stop-opacity=\"0\"/>\n" +
        "</linearGradient>\n" +
        "<linearGradient id=\"paint2_linear_6417_386\" x1=\"22\" y1=\"52\" x2=\"22\" y2=\"78.9474\" gradientUnits=\"userSpaceOnUse\">\n" +
        "<stop stop-color=\"#A815F3\"/>\n" +
        "<stop offset=\"1\" stop-color=\"#A815F3\" stop-opacity=\"0\"/>\n" +
        "</linearGradient>\n" +
        "<linearGradient id=\"paint3_linear_6417_386\" x1=\"22\" y1=\"52\" x2=\"22\" y2=\"78.9474\" gradientUnits=\"userSpaceOnUse\">\n" +
        "<stop stop-color=\"#A815F3\"/>\n" +
        "<stop offset=\"1\" stop-color=\"#A815F3\" stop-opacity=\"0\"/>\n" +
        "</linearGradient>\n" +
        "<linearGradient id=\"paint4_linear_6417_386\" x1=\"22\" y1=\"52\" x2=\"22\" y2=\"78.9474\" gradientUnits=\"userSpaceOnUse\">\n" +
        "<stop stop-color=\"#A815F3\"/>\n" +
        "<stop offset=\"1\" stop-color=\"#A815F3\" stop-opacity=\"0\"/>\n" +
        "</linearGradient>\n" +
        "<linearGradient id=\"paint5_linear_6417_386\" x1=\"22\" y1=\"52\" x2=\"22\" y2=\"78.9474\" gradientUnits=\"userSpaceOnUse\">\n" +
        "<stop stop-color=\"#A815F3\"/>\n" +
        "<stop offset=\"1\" stop-color=\"#A815F3\" stop-opacity=\"0\"/>\n" +
        "</linearGradient>\n" +
        "<linearGradient id=\"paint6_linear_6417_386\" x1=\"58\" y1=\"52\" x2=\"58\" y2=\"78.9474\" gradientUnits=\"userSpaceOnUse\">\n" +
        "<stop stop-color=\"#A815F3\"/>\n" +
        "<stop offset=\"1\" stop-color=\"#A815F3\" stop-opacity=\"0\"/>\n" +
        "</linearGradient>\n" +
        "<linearGradient id=\"paint7_linear_6417_386\" x1=\"58\" y1=\"52\" x2=\"58\" y2=\"78.9474\" gradientUnits=\"userSpaceOnUse\">\n" +
        "<stop stop-color=\"#A815F3\"/>\n" +
        "<stop offset=\"1\" stop-color=\"#A815F3\" stop-opacity=\"0\"/>\n" +
        "</linearGradient>\n" +
        "<linearGradient id=\"paint8_linear_6417_386\" x1=\"58\" y1=\"52\" x2=\"58\" y2=\"78.9474\" gradientUnits=\"userSpaceOnUse\">\n" +
        "<stop stop-color=\"#A815F3\"/>\n" +
        "<stop offset=\"1\" stop-color=\"#A815F3\" stop-opacity=\"0\"/>\n" +
        "</linearGradient>\n" +
        "<linearGradient id=\"paint9_linear_6417_386\" x1=\"58\" y1=\"52\" x2=\"58\" y2=\"78.9474\" gradientUnits=\"userSpaceOnUse\">\n" +
        "<stop stop-color=\"#A815F3\"/>\n" +
        "<stop offset=\"1\" stop-color=\"#A815F3\" stop-opacity=\"0\"/>\n" +
        "</linearGradient>\n" +
        "</defs>\n" +
        "</svg>\n"
},{
    "name": "Credentials dummy workload",
    "to": "/credentials-service",
    id: "credentials-test-workload",
    features: [
        {
            "title": "Dummy workload ",
            "id": "Credentials.DummyWorkload/active",
            "isAnon": true
        },
        {
            "title": "Dummy Workload - read inactive",
            "id": "Credentials.DummyWorkload/read-inactive",
            "isAnon": true
        },
        {
            "title": "Dummy Workload - automate inactive",
            "id": "Credentials.DummyWorkload/automate-inactive",
            "isAnon": true
        },
    ],
}];

