const accountMenuDataTest = {
    accountButton: 'account-accountButton',
    menu: {
        serviceAccountButton: 'account-menu-serviceAccount-button'
    },
    serviceAccount: {
        serviceAccountCounter: 'serviceAccount-counter',
        createServiceAccount: 'serviceAccount-createServiceAccount-button',
        createServiceAccountWhenNoServiceAccounts: 'serviceAccount-noExisting-createServiceAccount-button',
    }
}

export default accountMenuDataTest